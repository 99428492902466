import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getServiceById } from "../services/dataService";
import { useTitle } from "../hook/useTitle";
import { getServicesList } from "../services/dataService";
import { ServiceCard } from "../components/Elements/ServiceCard";
import { Contact } from "./Contact/Contact";

export const ServiceDetail = () => {

    const [service, setService] = useState([]);
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [services, setServices] = useState([]);

    const navigate = useNavigate();

    useTitle(service.title);

    useEffect(() => {
        async function fetchService() {
            try {
                const data = await getServiceById(id);
                console.log(data)
                setService(data);

            } catch (err) {
                setError("une erreur est survenue lors de la récup des services");
            } finally {
                setLoading(false);
            }
        };
        fetchService();
    }, [id]);

    useEffect(() => {
        const fetchServices = async () => {
            try {
                const data = await getServicesList();
                setServices(data);
            } catch (err) {
                setError("une erreur est survenue lors de la récup des services");
            } finally {
                setLoading(false);
            }
        };
        fetchServices();
    }, [])

    const handleClick = () => {
        navigate(`/nos-realisations`);
    };

    const goHome = () => {
        navigate(`/`);
    }

    if (loading) return (
        <span className="loading loading-spinner text-secondary"></span>
    )
    if (!service || !service.title) {
        return <p>Chargement...</p>;
    }

    return (
        <section>
            <main>
                <button
                    className="btn btn-outline btn-sm my-3 ml-2 flex justify-start" onClick={goHome}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
                    </svg>
                    retour
                </button>
                <article className="flex flex-col mb-3">
                    <img src={service.cover} alt="image-couverture-service" />
                    <h1 className="font-bold text-4xl mx-3 py-8 text-left">{service.title}</h1>
                    <p className="text-left mb-3 ml-2">{service.description}</p>
                    <p className="text-left mb-3 ml-2">{service.text}</p>
                </article>
                <article className="flex flex-col items-center mb-3">
                    <p className="mb-3">Exemple de chantier réalisé à {service.city}</p>
                    <img
                        src={service.image}
                        className="mb-3"
                        alt="photo-avant-après-chantier"
                    />
                    <button className="btn btn-outline btn-info btn-sm mx-8" onClick={handleClick}>Voir d'autres réalisations en photos</button>
                </article>
                <article className="flex flex-wrap gap-2 mx-3 py-8">
                    <button className="btn btn-primary no-animation rounded-full">TOUS</button>
                    {service.tags.map(tag => (
                        <button className="btn btn-primary no-animation rounded-full btn-outline">
                            {tag}
                        </button>
                    ))
                    }
                </article>

                <article className="services">
                    <h1 className="font-bold text-4xl text-left mx-3 py-8">Nos autres prestations</h1>
                    <article>
                        <div className="carousel w-full gap-2">
                            {services.filter(service => service.id !== id).map(filteredService => (
                                <ServiceCard
                                    key={filteredService.id}
                                    service={filteredService}
                                />
                            ))}
                        </div>
                    </article>
                </article>

                <article className="mb-3">
                    <Contact />
                </article>

            </main>
        </section>
    );
};
