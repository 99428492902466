import logo from '../../assets/logo-mwpierre-espaces-verts.png';
import React, { useState } from 'react';
import { Sidebar } from '../Elements/Sidebar';

export const Header = () => {

    const [show, setShow] = useState(false);

    return (
        <header className='bg-base-100 shadow-xl sticky top-0 z-10'>
            <div className="navbar bg-base-100 flex align-content-center">
                <div className="navbar-start ml-8 sm:ml-2">
                    <a href='/'>
                        <img
                            src={logo}
                            alt="logo MWPierre espaces verts"
                            width={200}
                        />
                    </a>
                </div>
                <div className='navbar-center hidden xl:flex'>
                    <ul className='bg-base-100 menu menu-horizontal px-1'>
                        <li>
                            <details>
                                <summary>Entretien des parcs et jardins</summary>
                                <ul className="bg-base-100 rounded-t-none p-2">
                                    <li><a href='/nos-services/10001'>Élagage d'arbre avec nacelle</a></li>
                                    <li><a href='/nos-services/10002'>Taille de haies et d'arbres fruitiers</a></li>
                                </ul>
                            </details>
                        </li>
                        <li>
                            <details>
                                <summary>Entretien de toiture</summary>
                                <ul className="bg-base-100 rounded-t-none p-2">
                                    <li><a href='/nos-services/10003'>Toiture</a></li>
                                    <li><a href='/nos-services/10003'>Traitement anti-mousse</a></li>
                                </ul>
                            </details>
                        </li>
                        <li>
                            <details>
                                <summary>Façade</summary>
                                <ul className="bg-base-100 rounded-t-none p-2">
                                    <li><a href='/nos-services/10005'>Ravalement de façade</a></li>
                                    <li><a href='/nos-services/10004'>Entretien de façade</a></li>
                                </ul>
                            </details>
                        </li>
                        <li><a href='#contact'>Devis & Contact</a></li>
                    </ul>
                </div>
                <div className="navbar-end">
                    <div className='dropdown dropdown-left'>
                        <button onClick={() => setShow(!show)} className="btn btn-square btn-ghost xl:hidden">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                className="inline-block h-5 w-5 stroke-current">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path>
                            </svg>
                        </button>
                    </div>
                    <div className="xl:flex hidden gap-1">
                        <button className="btn btn-primary rounded-full">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6">
                                <path fillRule="evenodd" d="M1.5 4.5a3 3 0 0 1 3-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 0 1-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 0 0 6.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 0 1 1.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 0 1-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5Z" clipRule="evenodd" />
                            </svg>
                            <a href="tel:+33659308438">06 59 30 84 38</a>
                        </button>
                        <button className="btn btn-primary rounded-full">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6">
                                <path fillRule="evenodd" d="M1.5 4.5a3 3 0 0 1 3-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 0 1-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 0 0 6.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 0 1 1.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 0 1-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5Z" clipRule="evenodd" />
                            </svg>
                            <a href="tel:+33381977254">03 81 97 72 54</a>
                        </button>
                    </div>
                </div>
            </div>
            <div className='xl:hidden flex flex-row gap-1 justify-center items-center bg-base-100'>
                <button className="btn btn-primary rounded-full mb-2">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6">
                        <path fillRule="evenodd" d="M1.5 4.5a3 3 0 0 1 3-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 0 1-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 0 0 6.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 0 1 1.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 0 1-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5Z" clipRule="evenodd" />
                    </svg>
                    <a href="tel:+33659308438">06 59 30 84 38</a>
                </button>
                <button className="btn btn-primary rounded-full mb-2">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6">
                        <path fillRule="evenodd" d="M1.5 4.5a3 3 0 0 1 3-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 0 1-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 0 0 6.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 0 1 1.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 0 1-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5Z" clipRule="evenodd" />
                    </svg>
                    <a href="tel:+33381977254">03 81 97 72 54</a>
                </button>
            </div>

            {show && <Sidebar setShow={setShow} />}
        </header>
    )
}

export default Header