import './Videos.css';

export const Videos = () => {
    return (
        <section id="real">
            <h2 className="font-bold text-4xl text-left mx-3 py-8">Découvrez nos vidéos de chantier</h2>
            <article className="flex flex-col gap-2 items-center xl:flex-row xl:justify-around mb-8">
                <div className="items-center">
                    <div className="video-container">
                        <iframe
                            src="https://www.youtube.com/embed/9DtiRFhAO5I"
                            title="YouTube video player"
                            frameBorder="0"
                        >
                        </iframe>
                    </div>
                </div>
                <div className="items-center">
                    <div className="video-container">
                        <iframe
                            src="https://www.youtube.com/embed/92ECctX3jw8"
                            title="YouTube video player"
                            frameBorder="0"
                        >
                        </iframe>
                    </div>
                </div>
            </article>
        </section>
    )
}