import React from "react";
import heroImage from './hero.webp';

export const Hero = () => {
    return (
        <div
            className="hero min-h-screen w-full"
            style={{
                backgroundImage: `url(${heroImage})`,
            }}>
            <div className="hero-overlay bg-opacity-40"></div>
            <div className="hero-content text-neutral-content text-center">
                <div className="max-w-md">
                    <h1 className="mb-5 text-4xl font-bold">Prenez soin de votre maison et de votre jardin toute l'année avec notre expertise en Franche-comté</h1>
                    <div className="flex flex-row items-center justify-around">
                        <a href="#services" className="btn btn-primary text-white rounded-full">Découvrir nos services</a>
                        <a href="#contact" className="btn btn-accent text-white rounded-full">Obtenir un devis</a>
                    </div>
                </div>
            </div>
        </div>
    )
}