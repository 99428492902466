import { Routes, Route } from "react-router-dom";
import React from "react";
import { Home, Services, ServiceDetail, Realisations, Contact, PageNotFound, MentionsLegales, Confidentialite } from '../pages/index';

export const AllRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/nos-services" element={<Services />} />
            <Route path="/nos-services/:id" element={<ServiceDetail />} />
            <Route path="/nos-realisations" element={<Realisations />} />
            <Route path="/contact" element={<Contact />} />

            <Route path="/mentions-legales" element={<MentionsLegales />} />
            <Route path="/confidentialite" element={<Confidentialite />} />


            <Route path="*" element={<PageNotFound />} />
        </Routes>
    )
}