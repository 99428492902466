import React, { useEffect } from 'react';
import './ElfsightWidget.css'

export const ElfsightWidget = () => {

    useEffect(() => {
        const scriptId = "elfsight-platform-script";
        if (!document.getElementById(scriptId)) {
            const script = document.createElement("script");
            script.src = "https://apps.elfsight.com/p/platform.js";
            script.id = scriptId;
            script.defer = true;
            document.body.appendChild(script);
            script.onload = () => {
                setTimeout(() => {
                    const container = document.querySelector(
                        ".elfsight-app-9a4a6e1a-2dd5-45b5-adb0-d77604c8367a"
                    );
                    if (container) {
                        container.style.height = `${container.offsetHeight}px`;
                    }
                }, 1000);
            };
        }
    }, []);

    return (
        <div className="widget-container">
            <div
                className="elfsight-app-9a4a6e1a-2dd5-45b5-adb0-d77604c8367a"
                data-elfsight-app-lazy
            ></div>
        </div>
    )
}