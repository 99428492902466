export const getServicesList = async () => {
    try {
        const response = await fetch(`https://site-paysagiste-default-rtdb.europe-west1.firebasedatabase.app/services.json?`);
        if (!response.ok) {
            throw { message: response.statusText, status: response.status };
        }
        const data = await response.json()
        return data ? Object.keys(data).map(key => ({ id: key, ...data[key] })) : [];

    } catch (err) {
        console.err(err)
    }


}

export const getServiceById = async (id) => {
    try {
        const response = await fetch(`https://site-paysagiste-default-rtdb.europe-west1.firebasedatabase.app/services.json?`);
        if (!response.ok) {
            throw { message: response.statusText, status: response.status };
        }
        //const data = await response.json();

        const textData = await response.text();  // Utilisez .text() pour voir la réponse brute


        const data = JSON.parse(textData);  // Parsez la réponse JSON

        // rechercher l'ID à l'intérieur de services
        const service = Object.values(data).find(item => parseInt(item.id, 10) === parseInt(id, 10));
        return service;

    } catch (err) {
        console.error(err);
    }
}
