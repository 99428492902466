import emailjs from '@emailjs/browser';
import React, { useRef } from 'react';

export const FormContact = () => {

    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
            .sendForm('service_xt3dxhz', 'contact_form', form.current, {
                publicKey: 'EEFVGCeti2q_U3_d2',
            })
            .then(
                () => {
                    console.log('SUCCESS!');
                },
                (error) => {
                    console.log('FAILED...', error.text);
                },
            );
    };

    return (
        <div>
            <p className="text-xl font-bold mb-5">Demander un devis par email :</p>
            <form className="flex flex-col items-center mt-5" ref={form} onSubmit={sendEmail}>

                <label className="form-control flex m-1">
                    <div className="label">
                        <span className="label-text">Quel est votre nom ?</span>
                    </div>
                    <input
                        type="text"
                        className="input input-bordered input-primary max-w-xs"
                        placeholder="Entrez votre nom et prénom ici."
                        required
                        name="user_name"
                        maxLength={150}
                    />
                </label>

                <label className="flex form-control m-1">
                    <div className="label">
                        <span className="label-text">Quel est votre email ?</span>
                    </div>
                    <input
                        type="text"
                        className="input input-bordered input-primary max-w-xs"
                        placeholder="entrer votre email ici."
                        required
                        name="user_email"
                        pattern="(?!(^[.-].*|[^@]*\.@|.*\.{2,}.*)|^.{254}.)([a-zA-Z0-9!#$%&'*+\/=?^_`{|}~.-]+@)(?!-.*|.*-\.)([a-zA-Z0-9-]{1,63}\.)+[a-zA-Z]{2,15}"
                        maxLength={60}
                    />
                </label>

                <label className="flex form-control m-1">
                    <div className="label">
                        <span className="label-text">Quel est votre numéro de téléphone?</span>
                    </div>
                    <input
                        type="text"
                        className="input input-bordered input-primary max-w-xs"
                        placeholder="Entrez votre numéro de téléphone"
                        required
                        name="user_tel"
                        maxLength={15}
                        minLength={10}
                        pattern="((\+|00)?[1-9]{2}|0)[1-9]( ?[0-9]){8}"
                    />
                </label>

                <label className="flex form-control">
                    <div className="label">
                        <span className="label-text">Description de votre demande</span>
                    </div>
                    <textarea
                        className="textarea textarea-bordered textarea-primary"
                        placeholder="Entrez votre demande ici."
                        name="message"
                        required
                        maxLength={250}
                    ></textarea>
                </label>

                <button type='submit' className="btn btn-primary rounded-full w-60 m-1 my-6">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6">
                        <path d="M3.478 2.404a.75.75 0 0 0-.926.941l2.432 7.905H13.5a.75.75 0 0 1 0 1.5H4.984l-2.432 7.905a.75.75 0 0 0 .926.94 60.519 60.519 0 0 0 18.445-8.986.75.75 0 0 0 0-1.218A60.517 60.517 0 0 0 3.478 2.404Z" />
                    </svg>
                    Envoyer
                </button>

            </form>
        </div>
    )
}