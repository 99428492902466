import logo from '../../assets/logo-mwpierre-espaces-verts.png';

export const Footer = () => {
    return (
        <footer className=" bg-base-200 p-10">
            <div className='text-left grid grid-col justify-center xl:grid-cols-4 md:grid-cols-2 mb-3 py-4'>
                <nav>
                    <h6 className='footer-title py-4'>Espace vert</h6>
                    <a href='/nos-services/10002' className="link link-hover block">Taille de Haies et d'arbres fruitiers</a>
                    <a href='/nos-services/10001' className="link link-hover block">Elagage d'arbre avec nacelle</a>
                    <p className="block">Abattage d'arbre difficile</p>
                    <p className="block">Entretien des parcs et des jardins</p>
                </nav>
                <nav>
                    <h6 className='footer-title py-4'>Toiture</h6>
                    <a href='/nos-services/10003' className="link link-hover block">Nettoyage haute pression</a>
                    <p className="block">Démoussage des tuiles</p>
                    <a href='/nos-services/10003' className="link link-hover block">Traitement anti-mousse</a>
                </nav>
                <nav>
                    <h6 className='footer-title py-4'>Façade</h6>
                    <a href='/nos-services/10005' className="link link-hover block">Ravalement de façade</a>
                    <p className="block">Nettoyage haute pression</p>
                    <p className="block">Démoussage des murs</p>
                    <p className="block">Petits travaux de maçonnerie</p>
                </nav>
                <nav>
                    <h6 className="footer-title py-4">Nos petits plus</h6>
                    <a href='/contact' className="link link-hover block">Devis gratuit</a>
                    <a href='/contact' className="link link-hover block">Déplacement gratuit</a>
                </nav>
            </div>
            <div className='w-full mb-3'>
                <h6 className='footer-title'>Nous intervenons à</h6>
                <div className="flex flex-wrap items-center justify-center">
                    <span className="text-slate-800 px-2">Montbéliard</span>
                    <span className="text-slate-800 px-2">Audincourt</span>
                    <span className="text-slate-800 px-2">Valentigney</span>
                    <span className="text-slate-800 px-2">Grand-Charmont</span>
                    <span className="text-slate-800 px-2">Seloncourt</span>
                    <span className="text-slate-800 px-2">Bethoncourt</span>
                    <span className="text-slate-800 px-2">Mandeure</span>
                    <span className="text-slate-800 px-2">Sochaux</span>
                    <span className="text-slate-800 px-2">Bavans</span>
                    <span className="text-slate-800 px-2">Bart</span>
                    <span className="text-slate-800 px-2">Voujeaucourt</span>
                    <span className="text-slate-800 px-2">Courcelles-les-Montbéliard</span>
                    <span className="text-slate-800 px-2">Mathay</span>
                    <span className="text-slate-800 px-2">Pont-de-Roide-Vermondans</span>
                    <span className="text-slate-800 px-2">Saint-Maurice-Colombier</span>
                    <span className="text-slate-800 px-2">Isle-Sur-le-Doubs</span>
                    <span className="text-slate-800 px-2">Dampierre-Sur-le-Doubs</span>
                    <span className="text-slate-800 px-2">Maîche</span>
                    <span className="text-slate-800 px-2">Saint-Hippolyte</span>
                    <span className="text-slate-800 px-2">Morteau</span>
                    <span className="text-slate-800 px-2">Territoire de Belfort (90) et alentours</span>
                </div>
            </div>
            <div className='grid grid-col justify-center items-center xl:grid-cols-3 py-4'>
                <div className='text-center xl:text-left py-4 items-center mb-3'>
                    <nav>
                        <h6 className='footer-title'>Plan du site</h6>
                        <a href='/nos-services' className="link link-hover block">Nos services</a>
                        <p className="block">Avis Clients</p>
                        <a href='/contact' className="link link-hover block">Devis gratuit et contact</a>
                        <a href='/nos-realisations' className="link link-hover block">Nos réalisations</a>
                    </nav>
                </div>
                <div className='flex flex-col w-96 mb-3'>
                    <button className="btn btn-primary rounded-full mb-3 text-2xl">
                        Chantier
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6">
                            <path fillRule="evenodd" d="M1.5 4.5a3 3 0 0 1 3-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 0 1-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 0 0 6.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 0 1 1.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 0 1-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5Z" clipRule="evenodd" />
                        </svg>
                        <a href="tel:+33659308438">06 59 30 84 38</a>
                    </button>
                    <button className="btn btn-primary rounded-full mb-3 text-2xl">
                        Bureau
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6">
                            <path fillRule="evenodd" d="M1.5 4.5a3 3 0 0 1 3-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 0 1-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 0 0 6.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 0 1 1.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 0 1-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5Z" clipRule="evenodd" />
                        </svg>
                        <a href="tel:+33381977254">03 81 97 72 54</a>
                    </button>
                </div>
                <div className='flex flex-col text-left mb-3'>
                    <img
                        src={logo}
                        alt="logo MWPierre espaces vert 25"
                        width={300}
                    />
                    <span className="text-gray-500 text-sm">Entretien des espaces verts et de l'extérieur du bâtiment</span>
                </div>
            </div>
            <div>
                <p className='text-slate-400 text-sm'>Copyright © {new Date().getFullYear()} - Tous droits réservés M.W.Pierre </p>
                <p className=' text-slate-500 text-sm'><a href='/mentions-legales'>Mentions Légales</a></p>
            </div>
        </footer >
    )
}
export default Footer