import React from 'react';
import { useTitle } from '../hook/useTitle';

export const Confidentialite = () => {

    useTitle('Politique de confidentialité')

    return (
        <section>
            <main>
                <div className='flex flex-col ml-2 text-left text-sm mb-3'>
                    <h2 className="text-3xl py-4 ml-2">Politique de Confidentialité</h2>
                    <p className='mb-3'>Nous accordons une grande importance à la confidentialité de vos données personnelles. <br /> Cette politique de confidentialité décrit comment nous collectons, utilisons, partageons et protégeons vos informations lorsque vous utilisez notre site web.</p>
                    <h3 className='mb-3'>1. Informations que nous collectons</h3>
                    <p className='mb-3'>Nous collectons les informations suivantes lorsque vous utilisez notre site :</p>
                    <ul className='list-disc'>
                        <li>Votre Nom, Prenom, Téléphone et Email : Si vous utilisez le formulaire de contact de devis.</li>
                    </ul>
                    <div className='divider'></div>
                    <h3 className='mb-3'>2. Utilisation de vos informations</h3>
                    <p className='mb-3'>Nous utilisons vos informations pour les raisons suivantes :</p>
                    <ul className='list-disc'>
                        <li>Fournir, exploiter et améliorer nos services.</li>
                        <li>Répondre à vos questions et demandes via notre formulaire de contact.</li>
                        <li>Analyser les statistiques de visite pour améliorer l'expérience utilisateur.</li>
                    </ul>
                    <div className='divider'></div>
                    <h3 className='mb-3'>3. Partage de vos informations</h3>
                    <p className='mb-3'>Nous ne vendons pas vos informations personnelles à des tiers. Cependant, nous pouvons partager vos données dans les cas suivants :</p>
                    <ul className='list-disc'>
                        <li>Si la loi l'exige.</li>
                        <li>Avec des prestataires de services tiers (hébergement, marketing, analyse, etc.).</li>
                        <li>Pour protéger nos droits ou prévenir des activités frauduleuses.</li>
                    </ul>
                    <div className='divider'></div>
                    <h3 className='mb-3'>4. Vos droits</h3>
                    <p className='mb-3'>Conformément au RGPD, vous disposez des droits suivants :</p>
                    <ul className='list-disc'>
                        <li>Accès et rectification : Vous pouvez demander une copie de vos données personnelles ou demander leur correction.</li>
                        <li>Suppression : Vous avez le droit de demander la suppression de vos données.</li>
                        <li>Opposition : Vous pouvez vous opposer à l'utilisation de vos données à des fins de marketing.</li>
                    </ul>
                    <p className='mb-3'>Pour exercer vos droits, contactez-nous à l'adresse :</p>
                    <div className='divider'></div>
                    <h3 className='mb-3'>5. Sécurité des données</h3>
                    <p className='mb-3'>Nous mettons en œuvre des mesures techniques et organisationnelles appropriées pour protéger vos informations contre l'accès non autorisé, la perte ou la modification.</p>
                    <div className='divider'></div>
                    <h3 className='mb-3'>6. Cookie</h3>
                    <p className='mb-3'>Notre site utilise des cookies pour :</p>
                    <ul className='list-disc'>
                        <li>Suivre les statistiques de visite (Google Analytics).</li>
                        <li>Améliorer les fonctionnalités de navigation (mémorisation des préférences).</li>
                    </ul>
                    <p className='mb-3'>Vous pouvez gérer vos préférences en matière de cookies dans votre navigateur.</p>
                    <div className='divider'></div>
                    <h3 className='mb-3'>7. Modifications de la politique de confidentialité</h3>
                    <p className='mb-3'>Nous nous réservons le droit de modifier cette politique à tout moment. Les modifications seront publiées sur cette page avec une nouvelle date de mise à jour.</p>
                    <div className='divider'></div>
                    <h3 className='mb-3'>8. Contact</h3>
                    <p className='mb-3'>Pour toute question concernant cette politique de confidentialité, vous pouvez nous contacter :</p>

                    <ul className='list-disc mb-3'>
                        <li>Email : artisanw.pierre@gmail.com</li>
                        <li>Téléphone :</li>
                    </ul>
                </div>
            </main>
        </section>
    )
}