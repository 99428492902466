import React from "react";
import { useTitle } from "../hook/useTitle";

export const MentionsLegales = () => {

    useTitle('Mentions légales')

    return (
        <section>
            <main>
                <div className=" flex flex-col text-left ml-2 text-sm">
                    <h2 className="text-3xl mb-3 py-4 ml-2">Mentions Légales</h2>

                    <p className="mb-3">Le site https://www.artisanmwpierre.fr est édité par : </p>
                    <p className="mb-3">Dénomination sociale : M.W.Pierre Espaces Verts</p>
                    <p className="mb-3">SIRET : 530 929 041 00016 </p>
                    <p className="mb-3">Email de contact : artisanw.pierre@gmail.com</p>

                    <div className="divider"></div>

                    <p className="mb-3">Le site https://www.artisanmwpierre.fr est hébergé par</p>
                    <p className="mb-3">Dénomination sociale : FIREBASE HOSTING par GOOGLE</p>
                    <p className="mb-3">Siège social : Google LLC, 1600 Amphitheatre Parkway,
                        Mountain View, California 94043 USA.</p>
                </div>
            </main>
        </section>
    )
}