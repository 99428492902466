import React, { useEffect, useState } from 'react';
import { useTitle } from '../../hook/useTitle';
import { useNavigate } from "react-router-dom";
import { getServicesList } from "../../services/dataService";
import { GaleryMansionGrid } from './components/GaleryMansionGrid';

export const Realisations = () => {

    useTitle("Découvrez nos derniers chantiers")

    const [services, setServices] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchServices = async () => {
            try {
                const data = await getServicesList();
                setServices(data);
            } catch (err) {
                setError("une erreur est survenue lors de la récupération des services");
            } finally {
                setLoading(false);
            }
        };
        fetchServices();
    }, [])

    const goHome = () => {
        navigate(`/`);
    }

    return (
        <section>
            <main>
                <button
                    className="btn btn-outline btn-sm my-3 ml-2 flex justify-start" onClick={goHome}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
                    </svg>
                    Retour
                </button>
                <h1 className='font-bold mx-3 py-4 text-left text-4xl'>Découvrez nos derniers chantiers</h1>
                <p className='text-left ml-2 mb-3'>Des photos Avant-Après qui permettent de mesurer la qualité de notre travail et le rendu final des travaux.</p>
                {loading ? (
                    <p className="text-center mt-6">Chargement des services...</p>
                ) : error ? (
                    <p className="text-center text-red-500 mt-6">{error}</p>
                ) : (
                    <article className="grid gap-4 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 grid-flow-dense p-6">
                        {services.map((service) => (
                            <GaleryMansionGrid key={service.id} image={service.image} id={service.id} />
                        ))}
                    </article>
                )}
            </main>
        </section>
    )
}